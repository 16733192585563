// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// graphql
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
// components
import Settings from './components/settings';
import RtlLayout from './components/@basic/RtlLayout';
import ScrollToTop from './components/@basic/ScrollToTop';
import LoadingScreen from './components/@basic/LoadingScreen';
import GoogleAnalytics from './components/@basic/GoogleAnalytics';
import NotistackProvider from './components/@basic/NotistackProvider';
import ThemePrimaryColor from './components/@basic/ThemePrimaryColor';
import ThemeLocalization from './components/@basic/ThemeLocalization';

// ----------------------------------------------------------------------

const client = new ApolloClient({
  uri: 'https://journley-graphql-server.llamaforceone.workers.dev/',
  cache: new InMemoryCache(),
});

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <ApolloProvider client={client}>
              <NotistackProvider>
                <Settings />
                <ScrollToTop />
                <GoogleAnalytics />
                {isInitialized ? <Router /> : <LoadingScreen />}
              </NotistackProvider>
            </ApolloProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
