import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Profile, User, UserPost, NotificationSettings } from '../../@types/user';
import { FLUENCY_LEVEL } from '../../@types/learning';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export enum RegistrationStep {
  UNKNOWN = -1,
  ABOUT_YOU = 0,
  LANGUAGES_FLUENT = 1,
  LANGUAGES_LEARNING = 2,
  GOALS = 3,
  COMPLETE = 100,
}

export type UserState = {
  isLoading: boolean;
  error: boolean;
  user: null | User;
  myProfile: null | Profile;
  posts: UserPost[];
  notifications: NotificationSettings | null;
  registration: {
    activeStep: RegistrationStep;
  };
};

// Make sure to update resetUser() too
// TODO: DRY up the fields from here & resetUser
const initialState: UserState = {
  isLoading: false,
  error: false,
  user: null,
  myProfile: null,
  posts: [],
  notifications: null,
  registration: {
    activeStep: RegistrationStep.UNKNOWN,
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetUser(state) {
      state.registration.activeStep = RegistrationStep.UNKNOWN;
      state.user = null;
      state.myProfile = null;
      state.posts = [];
      state.notifications = null;
    },

    onBackStep(state) {
      if (state.registration.activeStep !== RegistrationStep.ABOUT_YOU) {
        state.registration.activeStep -= 1;
      }
    },

    onNextStep(state) {
      if (state.registration.activeStep !== RegistrationStep.COMPLETE) {
        state.registration.activeStep += 1;
      }
    },

    setRegistrationStep(state, action) {
      state.isLoading = false;
      state.registration.activeStep = action.payload;
    },

    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload.results;

      if (state.registration.activeStep === RegistrationStep.UNKNOWN) {
        let activeStep = RegistrationStep.LANGUAGES_FLUENT;

        if (state.user?.languages.find((l) => l.isFluentSpeaker)) {
          activeStep = RegistrationStep.LANGUAGES_LEARNING;
        } else if (state.user?.languages.find((l) => !l.isFluentSpeaker)) {
          activeStep = RegistrationStep.GOALS;
        }
        state.registration.activeStep = activeStep;
      }
    },

    createUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload.results;
    },

    createUserLanguageSuccess(state, action) {
      state.isLoading = false;
      if (state.user) {
        state.user.languages = action.payload.results;
      }
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onNextStep, onBackStep, resetUser } = slice.actions;

// ----------------------------------------------------------------------

export function createUser(values: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      fetch('/api/v1/users/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return res;
        })
        .then((data) => {
          dispatch(slice.actions.createUserSuccess(data));
          dispatch(slice.actions.onNextStep());
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserById(userId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      fetch(`/api/v1/users/${userId}`)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          dispatch(slice.actions.getUserSuccess(data));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserByAuthId(userAuthId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      fetch(`/api/v1/users?auth_user_id=${userAuthId}`)
        .then((res) => {
          if (res.status === 204) {
            return res;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          if (data.status === 204) {
            dispatch(slice.actions.setRegistrationStep(RegistrationStep.ABOUT_YOU));
          } else {
            dispatch(slice.actions.getUserSuccess(data));
          }
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUserLanguage(
  userId: number,
  languageId: number,
  fluencyLevel: FLUENCY_LEVEL,
  isFluentSpeaker: boolean,
) {
  return async () => {
    const values = {
      languageId,
      fluencyLevel,
      isFluentSpeaker,
    };
    try {
      fetch(`/api/v1/user_languages/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return res;
        })
        .then((data) => {
          dispatch(slice.actions.createUserLanguageSuccess(data));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteUserLanguage(authUserId: string, userId: number, languageId: number) {
  return async () => {
    try {
      fetch(`/api/v1/user_languages/${userId}/${languageId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-key': authUserId,
        },
      }).then(() => {
        dispatch(getUserById(userId));
      });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
