import { useTranslation } from 'react-i18next';
// material
import { enUS, jaJP } from '@material-ui/core/locale';

// ----------------------------------------------------------------------

// More flag icons available here: https://uxwing.com

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/static/icons/ic_flag_en.svg',
  },
  {
    label: '日本語',
    value: 'jp',
    systemValue: jaJP,

    icon: '/static/icons/ic_flag_jp.svg',
  },
  // ./journley npm run gen-xx-loc
  {
    label: 'XX',
    value: 'xx',
    systemValue: enUS,

    icon: '/static/icons/ic_flag_xx.svg',
  },
  // ./journley npm run gen-xxlong-loc
  {
    label: 'XX-long',
    value: 'xxLong',
    systemValue: enUS,

    icon: '/static/icons/ic_flag_xxlong.svg',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  // TODO: filter out the pseudo-loc versions from LANG if the user is not an admin

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
