import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AUTH, PATH_JOURNAL } from '../routes/paths';
// redux
import { useDispatch, useSelector, RootState } from '../redux/store';
import {} from '../@types/user';
// utils
import { getUserByAuthId, RegistrationStep } from '../redux/slices/user';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, user: authUser } = useAuth();
  const dispatch = useDispatch();

  const {
    user: reduxUser,
    isLoading,
    registration: { activeStep },
  } = useSelector((state: RootState) => state.user);

  const noChangeResult = <>{children}</>;

  if (isAuthenticated) {
    if (isLoading) {
      return noChangeResult;
    }

    switch (activeStep) {
      case RegistrationStep.ABOUT_YOU:
      case RegistrationStep.LANGUAGES_FLUENT:
      case RegistrationStep.LANGUAGES_LEARNING:
      case RegistrationStep.GOALS:
        return <Navigate to={PATH_AUTH.registerAbout} />;
      case RegistrationStep.COMPLETE:
        return <Navigate to={PATH_JOURNAL.feed} />;
    }

    // If we haven't already fetched the journley user & cached, do so now
    if (!reduxUser && activeStep === RegistrationStep.UNKNOWN) {
      dispatch(getUserByAuthId(authUser?.id));
      return noChangeResult;
    }
  }

  return noChangeResult;
}
