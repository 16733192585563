// routes
import { PATH_DASHBOARD, PATH_ADMIN, PATH_JOURNAL } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/@basic/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const sidebarConfig = [
  // Journley
  // ----------------------------------------------------------------------
  {
    subheader: 'Journal',
    items: [
      {
        title: 'feed',
        path: PATH_JOURNAL.feed,
        icon: ICONS.analytics,
      },
      {
        title: 'practice',
        path: PATH_JOURNAL.practice,
        icon: ICONS.analytics,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
        ],
      },
    ],
  },

  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [
      {
        title: 'stats',
        path: PATH_ADMIN.stats,
        icon: ICONS.analytics,
      },
    ],
  },
];

export default sidebarConfig;
