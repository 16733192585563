import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import languageIcon from '@iconify/icons-ic/baseline-language';
import profileIcon from '@iconify/icons-ic/outline-assignment-ind';
// routes
import { PATH_PAGE, PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Languages',
    icon: <Icon icon={languageIcon} {...ICON_SIZE} />,
    path: PATH_PAGE.languages,
  },
  {
    title: 'Sign in',
    icon: <Icon icon={profileIcon} {...ICON_SIZE} />,
    path: PATH_AUTH.login,
  },
];

export default menuConfig;
