import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
// TODO: Uncomment when re-implemented
// import facebookFill from '@iconify/icons-eva/facebook-fill';
// material
import { Grid, Button, Divider, Typography } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  // TODO: Uncomment when it's reimplemented
  const { loginWithGoogle /*loginWithFaceBook*/ } = useAuth();

  const handleLoginGoogle = async () => {
    try {
      await loginWithGoogle?.();
    } catch (error: any) {
      console.error(error);
    }
  };

  // TODO: Uncomment when it's reimplemented
  // const handleLoginFaceBook = async () => {
  //   try {
  //     await loginWithFaceBook?.();
  //   } catch (error: any) {
  //     console.error(error);
  //   }
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleLoginGoogle}
          >
            <Icon icon={googleFill} color="#DF3E30" height={24} />
          </Button>
        </Grid>
        {/*
        // TODO: Come back and implement when the site is hosted live. FB social
        // login doesn't work with localhost.
        <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleLoginFaceBook}
          >
            <Icon icon={facebookFill} color="#1877F2" height={24} />
          </Button>
        </Grid> */}
      </Grid>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
