import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// redux
import { useDispatch, useSelector, RootState } from '../redux/store';
import { getUserByAuthId, RegistrationStep } from '../redux/slices/user';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, user: authUser } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const {
    user: reduxUser,
    isLoading,
    registration: { activeStep: registrationActiveStep },
  } = useSelector((state: RootState) => state.user);
  const shouldFetchUser =
    !reduxUser && !isLoading && registrationActiveStep === RegistrationStep.UNKNOWN;

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (shouldFetchUser) {
    dispatch(getUserByAuthId(authUser?.id));
  }

  return <>{children}</>;
}
